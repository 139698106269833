import React, { useState } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { RootUser } from '../interfaces/interfaces'

type Props = {
    rootUser: RootUser
    logoutAction: () => void
    dashboardClicked: () => void
}

function NavbarComponent({ rootUser, logoutAction, dashboardClicked }: Props) {
    return (
        <Navbar fixed="top" bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="#home">Jigsaw Super</Navbar.Brand>
                <Nav className="ms-auto">
                    <Nav.Link onClick={() => dashboardClicked()}>Dashboard</Nav.Link>
                    <Nav.Link onClick={() => logoutAction()}>
                        Logout
                    </Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default NavbarComponent