export interface Company {
    id: number
    name: string
}

export interface Report {
    id: number
    name: string
    createdByUserId: number
    createTime: number
    status: ReportStatus
    emailSubject: string
    emailBody: string
}
export interface ReportVersion {
    id: number
    reportId: number
    updateTime: number
    templateExcelUserFileId: string
}
export enum ReportStatus {
    Active = "Active",
    Draft = "Draft"
}
export interface Chart {
    id: number
    reportVersionId: number
    type: "pie" | "bar" | "pie3d" | "doughnut"
    title: string
    valueUnit: string
    showOnDashboard: boolean
    showOnWhatsapp: boolean
    showOnEmail: boolean
    points: { key: string, value: string | number }[]
    createTime: number
}
export interface PieChart extends Chart { }
export interface BarChart extends Chart { }
export interface LineChart extends Chart { }
export interface DoughnutChart extends Chart { }

export interface PivotTable {
    id: number
    title: string
    header: string[]
    body: (string | number)[][]
    footer: string[]
    showOnDashboard?: boolean
    createTime: number
}

export interface DataSource {
    id: number
    name: string
}
export interface RootUser {
    id: number
    name: string
    email: string
}
export interface User {
    id: number
    name: string
    companyId: number
    role: UserRole
    status: UserStatus
    email: string
    phoneNumber?: string
    linkedInId?: string
    employeeId?: string
    designation?: string
    department?: string
}
export enum UserRole {
    Admin = "Admin",
    Executive = "Executive",
    Analyst = "Analyst"
}
export enum UserStatus {
    Active = "Active",
    Disabled = "Disabled"
}
export interface UserGroup {
    id: number
    name: string
    companyId: number
}

export interface UserFile {
    id: string
    path: string
    name: string
    sheetNames: UserFileSheetName[]
    createTime: number
}

export interface UserFileSheetName {
    index: number;
    name: string;
    isDataDump: boolean;
}

export function getDummyReport(): Report {
    return {
        id: 0,
        name: "New Report",
        createdByUserId: 1,
        status: ReportStatus.Draft,
        createTime: Date.now(),
        emailSubject: null,
        emailBody: null,
    }
}