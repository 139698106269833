import React, { useState } from 'react';
import logo from './logo.svg';
import { Accordion, Card, Col, Container, Form, FormControl, Navbar, Row, Table, useAccordionButton } from 'react-bootstrap';
import NavbarComponent from '../components/Navbar';
import { Company, RootUser, User, UserRole, UserStatus } from '../interfaces/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faCaretDown, faCheck, faPlus, faPlusCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { RestS } from '../rest/rest-service';

type Props = {
    rootUser: RootUser
    companies: Company[]
    users: User[]
    reloadApp: () => Promise<void>
}

function Dashboard({ rootUser, companies, users, reloadApp }: Props) {

    const [loading, setLoading] = useState<boolean>(false)
    const [newCompany, setNewCompany] = useState<Company>()

    return (
        <Container style={{ marginTop: "80px" }}>
            {(!Boolean(newCompany) && (
                <Row>
                    <Col>
                        <div className='btn btn-sm btn-success'
                            onClick={() => {
                                setNewCompany({
                                    id: 0,
                                    name: null
                                } as Company)
                            }}>
                            <FontAwesomeIcon icon={faPlus} className='me-1' />
                            New Company
                        </div>
                    </Col>
                </Row>
            ))}
            {(Boolean(newCompany) && (
                <Row>
                    <Col className="col-4 mx-auto">
                        <div className="card card-body">
                            <FormControl
                                type="text"
                                value={newCompany.name}
                                placeholder="Company Name"
                                onChange={(e) => {
                                    newCompany.name = e.target.value
                                    setNewCompany(newCompany)
                                }} />
                            <hr />
                            <Row className="mt-3">
                                <Col>
                                    <div
                                        className="btn btn-sm btn-success"
                                        onClick={() => {
                                            setLoading(true)
                                            RestS.newCompany(newCompany).then((company: Company) => {
                                                reloadApp().then(() => {
                                                    setNewCompany(null)
                                                    setLoading(false)
                                                }).catch(e => console.error(e))
                                            }).catch(e => console.error(e))
                                        }}>
                                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                                        Save
                                    </div>
                                    <div
                                        className="btn btn-sm btn-secondary ms-2"
                                        onClick={() => {
                                            setNewCompany(null)
                                        }}>
                                        <FontAwesomeIcon icon={faTimes} className="me-2" />
                                        Discard
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            ))}
            <Row className="mt-3">
                <Col>
                    <Accordion defaultActiveKey="0" flush alwaysOpen>
                        {companies.map((company: Company) => (
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col className='col'>
                                            <div className="d-flex flex-col justify-content-between">
                                                <div className="fw-bold">
                                                    {company.id}) {company.name}
                                                </div>
                                                <div>
                                                    {users.filter(u => u.companyId === company.id).length} Accounts
                                                </div>
                                                <div className="">
                                                    <CustomAccordionToggle eventKey={company.id.toString()}>
                                                        <FontAwesomeIcon icon={faCaretDown} />
                                                    </CustomAccordionToggle>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse eventKey={company.id.toString()}>
                                    <Card.Body>
                                        <CompanyCardBody
                                            users={users.filter(u => u.companyId === company.id)}
                                            company={company}
                                            reloadApp={reloadApp} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;

type Props2 = {
    company: Company
    users: User[]
    reloadApp: () => Promise<void>
}

function CompanyCardBody({ company, users, reloadApp }: Props2) {

    const [loading, setLoading] = useState<boolean>(false)
    const [newUser, setNewUser] = useState<User>()
    const [newUserPassword, setNewUserPassword] = useState<string>()

    if (loading) {
        return (
            <Row>
                <Col className="text-center py-5">
                    Loading ...
                </Col>
            </Row>
        )
    } else {
        if (newUser) {
            return (
                <Row>
                    <Col className="col-4 mx-auto">
                        <div className="card card-body">
                            <FormControl
                                type="text"
                                value={newUser.name}
                                placeholder="User Name"
                                onChange={(e) => {
                                    newUser.name = e.target.value
                                    setNewUser(newUser)
                                }} />
                            <FormControl
                                type="text"
                                value={newUser.email}
                                className="mt-3"
                                placeholder="Email Address"
                                onChange={(e) => {
                                    newUser.email = e.target.value
                                    setNewUser(newUser)
                                }} />
                            <FormControl
                                type='text'
                                value={newUserPassword}
                                className="mt-3"
                                placeholder="Password"
                                onChange={(e) => {
                                    setNewUserPassword(e.target.value)
                                }} />

                            <div className="mt-3 px-2">
                                <div>User Role</div>
                                <Form.Check
                                    inline
                                    label="Admin"
                                    name="userRole"
                                    type={'radio'}
                                    id={`inline-1`}
                                    onClick={() => {
                                        newUser.role = UserRole.Admin
                                    }}
                                />
                                <Form.Check
                                    inline
                                    label="Executive"
                                    name="userRole"
                                    type={'radio'}
                                    id={`inline-2`}
                                    onClick={() => {
                                        newUser.role = UserRole.Executive
                                    }}
                                />
                                <Form.Check
                                    inline
                                    label="Analyst"
                                    name="userRole"
                                    type={'radio'}
                                    id={`inline3`}
                                    onClick={() => {
                                        newUser.role = UserRole.Analyst
                                    }}
                                />
                            </div>
                            <hr />
                            <Row className="mt-3">
                                <Col>
                                    <div
                                        className="btn btn-sm btn-success"
                                        onClick={() => {
                                            setLoading(true)
                                            RestS.newUser(newUser, newUserPassword).then((newUser: User) => {
                                                reloadApp().then(() => {
                                                    setNewUser(null)
                                                    setLoading(false)
                                                }).catch(e => console.error(e))
                                            }).catch(e => console.error(e))
                                        }}>
                                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                                        Save
                                    </div>
                                    <div
                                        className="btn btn-sm btn-secondary ms-2"
                                        onClick={() => {
                                            setNewUser(null)
                                            setNewUserPassword(null)
                                        }}>
                                        <FontAwesomeIcon icon={faTimes} className="me-2" />
                                        Discard
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col>
                        <div
                            className='btn btn-sm btn-success'
                            onClick={() => {
                                setNewUser({
                                    id: 0,
                                    companyId: company.id,
                                    role: UserRole.Analyst,
                                    status: UserStatus.Active
                                } as User)
                            }}>
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            New Account
                        </div>
                        <div
                            className='btn btn-sm btn-warning ms-2'
                            onClick={() => {
                                if (window.confirm(`Delete company ${company.name}?`) == true) {
                                    setLoading(true)
                                    RestS.deleteCompany(company.id).then(() => {
                                        reloadApp().then(() => {
                                            setLoading(false)
                                        }).catch(e => console.error(e))
                                    }).catch(e => console.error(e))
                                }
                            }}>
                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                            Delete Company
                        </div>
                        {(Boolean(users.length) && (
                            <>
                                <hr />
                                <Table responsive="md">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user: User) => (
                                            <tr key={user.id}>
                                                <td>{user.id}</td>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.role}</td>
                                                <td>
                                                    <div className='btn btn-sm btn-warning'
                                                        onClick={() => {
                                                            if(window.confirm(`Delete user ${user.name}?`)) {
                                                                setLoading(true)
                                                                RestS.deleteUser(user.id).then(() => {
                                                                    reloadApp().then(() => {
                                                                        setLoading(false)
                                                                    }).catch(e => console.error(e))
                                                                }).catch(e => console.error(e))
                                                            }
                                                        }}>
                                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                        Delete
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        ))}
                        {(Boolean(users.length) && (
                            <>
                                <hr />
                                <div className='mt-3 d-block text-center'>
                                    (No Accounts Yet)
                                </div>
                            </>
                        ))}
                    </Col>
                </Row>
            )
        }
    }
}


function CustomAccordionToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => { })

    return (
        <button
            className='btn btn-info btn-sm'
            onClick={decoratedOnClick}>
            {children}
        </button>
    );
}