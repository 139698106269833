import React, { useEffect, useState } from 'react';
import './App.css';
import Dashboard from './pages/Dashboard';
import NavbarComponent from './components/Navbar';
import { Company, RootUser, User } from './interfaces/interfaces';
import { Container } from 'react-bootstrap';
import RootLoginPage from './pages/RootLogin';
import { RestS } from './rest/rest-service';

function App() {

    const [loading, setLoading] = useState<boolean>()
    const [rootUser, setRootUser] = useState<RootUser>()
    const [companies, setCompanies] = useState<Company[]>()
    const [users, setUsers] = useState<User[]>()

    function loadData(): Promise<void> {
        return new Promise((resolve, reject) => {
            RestS.getAllCompanies().then((companies) => {
                RestS.getAllUsers().then((users) => {
                    setCompanies(companies)
                    setUsers(users)
                    resolve()
                }).catch(reject)
            }).catch(reject)
        })
    }

    function rootUserLoginSuccess(rootUser: RootUser) {
        setLoading(true)
        setRootUser(rootUser)
        loadData().then(() => {
            setLoading(false)
        }).catch(e => {
            console.error(e)
            setLoading(false)
        })
    }

    function logoutAction() {
        setLoading(true)
        localStorage.removeItem('HELLO12')
        setRootUser(null)
        setCompanies(null)
        setUsers(null)
        setLoading(false)
    }

    useEffect(() => {
        let jwt: string = localStorage.getItem('HELLO12')
        if (Boolean(jwt)) {
            setLoading(true)
            setRootUser(null)
            RestS.verifyJWT(jwt).then((rootUser: RootUser) => {
                setRootUser(rootUser)
                loadData().then(() => {
                    setLoading(false)
                }).catch(e => {
                    console.error(e)
                    setLoading(false)
                })
            }).catch(error => {
                console.error(error)
                setRootUser(null)
                setLoading(false)
            })
        }
    }, [])

    if (loading) {
        return (
            <Container>
                Loading...
            </Container>
        )
    } else {
        if (rootUser) {
            return (
                <>
                    <NavbarComponent
                        rootUser={rootUser}
                        logoutAction={logoutAction}
                        dashboardClicked={() => {

                        }} />
                    <Dashboard
                        reloadApp={loadData}
                        rootUser={rootUser}
                        users={users}
                        companies={companies} />
                </>
            )
        } else {
            return <RootLoginPage rootUserLoginSuccess={rootUserLoginSuccess} />
        }
    }
}

export default App;
