import React, { useState } from 'react'
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import { RootUser } from '../interfaces/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import logo from '../logo.svg'
import { RestS } from '../rest/rest-service'

type Props = {
    rootUserLoginSuccess: (rootUser: RootUser) => void
}

function RootLoginPage({ rootUserLoginSuccess }: Props) {

    const [loginError, setLoginError] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [rememberMe, setRememberMe] = useState<boolean>(false)
    const [loginLoading, setLoginLoading] = useState<boolean>(false)

    return (
        <Container>
            <Row>
                <Col className='col-4 mx-auto'>
                    <Row style={{ marginTop: '100px' }}>
                        <Col>
                            <FormControl
                                type="text"
                                placeholder="Email Address"
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }} />
                            <FormControl
                                type='password'
                                className="mt-3"
                                placeholder="Password"
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }} />
                            <Form.Check
                                className="mt-3"
                                type={"switch"}
                                label={"Remember This Computer"}
                                style={{ cursor: "pointer" }}
                                onChange={(e) => {
                                    setRememberMe(Boolean(e.target.value))
                                }}
                            />
                        </Col>
                    </Row>
                    {Boolean(loginError) && (
                        <Row className="mt-3">
                            <Col>
                                <span className="text-danger">
                                    {loginError}
                                </span>
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-3">
                        <Col>
                            <Button
                                onClick={() => {
                                    setLoginLoading(true)
                                    RestS.loginUser(email, password).then(({ user, jwt }) => {
                                        localStorage.setItem('HELLO12', jwt)
                                        setLoginLoading(false)
                                        rootUserLoginSuccess(user)
                                    }).catch(error => {
                                        setLoginLoading(false)
                                        setLoginError("Login Failed")
                                    })
                                }}
                                disabled={loginLoading}>
                                {loginLoading ? "Loading..." : "Login"}
                                {!loginLoading && (
                                    <FontAwesomeIcon icon={faAnglesRight} className="ms-2" />
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default RootLoginPage;
